<template>
  <div class="login">
    <div class="headDiv"></div>
    <div class="head_title">
      <h1>welcome</h1>
      <h1>临港南大数智云</h1>
    </div>
    <div style="height: 25%; justify-content: space-between">
      <img
        style="margin: 0 auto; display: block"
        src="../../assets/mobileImg/login/Group 505.png"
        alt=""
      />
    </div>
    <van-form @submit="onSubmit" class="login_form flexcolumn">
      <div class="login_title"></div>
      <div class="flex myLoginInput">
        <div style="margin-left: 10px">
          <img
            style="width: 100%; display: block; height: 100%"
            src="../../assets/mobileImg/login/Frame.png"
            alt=""
          />
        </div>
        <van-field
          v-model="username"
          name="用户名"
          label=""
          :label-width="30"
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '' }]"
        />
      </div>
      <div class="flex myLoginInput">
        <div style="margin-left: 10px">
          <img
            style="width: 100%; display: block; height: 100%"
            src="../../assets/mobileImg/login/Frame-1.png"
            alt=""
          />
        </div>
        <van-field
          v-model="password"
          type="password"
          :label-width="30"
          name="密码"
          label=""
          placeholder="请输入密码"
          :rules="[{ required: true, message: '' }]"
        />
      </div>

      <div class="flex" style="width: 80%; justify-content: space-between">
        <p style="color: #1989fa" @click="$router.push('AddUser')">注册</p>
        <p style="color: #b7b7b7" @click="$router.push('GetBackPassword')">
          忘记密码?
        </p>
      </div>

      <div style="width: 100%">
        <van-button
          round
          block
          type="info"
          class="myButton"
          native-type="submit"
          ><span>登录</span></van-button
        >
      </div>
      <!-- <div class="flex">
        <img
          src="../../assets/mobileImg/vx.png"
          @click="WxLogin"
          width="30"
          alt=""
        />
        <img
          src="../../assets/mobileImg/iphone.png"
          width="35"
          style="margin-left: 10px"
          alt=""
        />
      </div> -->
    </van-form>
    <!-- <van-loading
      type="spinner"
      color="#1989fa"
      style="margin: 120px auto 0; text-align: center"
      vertical
      v-else
      >登录中...</van-loading
    > -->
    <div class="flex loginfooter">
      <!-- <div @click="$router.push('GetBackPassword')"> -->
      <div class="flexLR">
        <van-checkbox
          v-model="checked"
          style="margin-right: 4px"
          checked-color="#1989fa"
          icon-size="14"
        ></van-checkbox>
        <p style="color: #909090">我已阅读并同意</p>
        <span
          style="color: #00adff"
          @click="
            $router.push({ name: 'xtbInfo', params: { username, password } })
          "
          >《协同宝协议》</span
        >
      </div>
    </div>
  </div>
</template>

<script>
// import wx from "weixin-js-sdk";
import { login, GetUserInfo, MiniprogramLogin } from "@/api";
export default {
  data() {
    return {
      username: "",
      password: "",
      checkedItem: true,
      loading: false,
      checked: false,
      show: true,
    };
  },
  methods: {
    WxLogin() {
      if (this.checked) {
        localStorage.setItem("url", "https://lgapi.eabim.cn/");
        // localStorage.setItem("url", "http://192.168.1.66:64305");
        // localStorage.setItem("url", "http://192.168.1.225:64305");
        wx.miniProgram.navigateTo({
          url: `/pages/logs/logs`,
        });
      } else {
        this.$Toast.fail("请同意并阅读协同宝协议");
      }
    },
    async onSubmit() {
      if (this.checked) {
        this.loading = true;
        this.show = false;
        let params = {
          Login: this.username,
          Pwd: this.password,
        };
        let res = await login(params);
        if (res.Code == 1) {
          localStorage.setItem("token", res.Data.token);
          localStorage.setItem("isLogin", true);
          this.$store.state.token = res.Data.token;
          this.$store.state.uid = res.Data.uid;
          console.log(res.Data.uid);
          // 保存账号密码
          localStorage.setItem("zhanghao", this.username);
          localStorage.setItem("mima", Base64.encode(this.password));
          let userRes = await GetUserInfo({
            params: {
              id: res.Data.uid,
            },
          });
          this.$store.state.userImg = userRes.Data.user13;
          this.$store.state.username = userRes.Data.user04;
          this.$Notify({ type: "success", message: "登录成功" });
          this.$router.push({
            path: "/project",
          });
          localStorage.setItem("url", "https://lgapi.eabim.cn/");
          // localStorage.setItem("url", "http://192.168.1.66:64305");
        } else {
          this.$Notify({ type: "danger", message: res.Message });
          this.show = true;
          this.loading = false;
        }
      } else {
        this.$Toast.fail("请同意并阅读协同宝协议");
      }
    },
    // 获取密码
    getUserPassword(is) {
      if (is) {
        localStorage.setItem("zhanghao", this.username);
        localStorage.setItem("mima", Base64.encode(this.password));
      } else {
        localStorage.setItem("zhanghao", "");
        localStorage.setItem("mima", "");
      }
    },
  },
  created() {
    // 记住密码
    let account = localStorage.getItem("zhanghao");
    let password = localStorage.getItem("mima");
    if (account && password) {
      this.username = account;
      this.password = Base64.decode(password);
    }
    if (this.$route.params.is) {
      this.checked = true;
      this.username = this.$route.params.username;
      this.password = this.$route.params.password;
    }
    if (window.location.href.indexOf("wxLoginCode") != -1) {
      this.show = true;
      let url = window.location.href.substr(
        window.location.href.indexOf("wxLoginCode")
      );
      url = url.replace("wxLoginCode=", "");
      let code = url;
      MiniprogramLogin({ code }).then((res) => {
        if (res.Code > 0) {
          localStorage.setItem("token", res.Data.token);
          localStorage.setItem("isLogin", true);
          this.$store.state.token = res.Data.token;
          this.$store.state.uid = res.Data.uid;
          this.$router.push({
            path: "/project",
          });
        }
        this.$Toast(res.Message);
      });
      this.show = false;
    }
  },
  mounted() {
    // this.pdfTypeImg();
  },
};
</script>

<style lang="scss" scoped>
.headDiv {
  height: 22%;
  width: 100%;
  // background-image: linear-gradient(to bottom, #728dd3 0%, #54e6ef 100%);
  border-radius: 0 0 15% 15%;
  position: relative;
  transform: skewY(6deg);
  top: -6%;
}
.myLoginInput {
  width: 90%;
  border-radius: 50px;
  background-color: #e8e8e8;
}
.login {
  width: 100%;
  height: 100vh;
  background: #fff;
  background-image: url("../../assets/mobileImg/login/bj.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .login_form {
    margin: 30px auto;
    width: 85%;
    height: 500px;
    border-radius: 20px;
    .login_title {
      text-align: center;
      font-size: 32px;
      height: 0px;
      line-height: 100px;
      font-weight: 700;
      color: #000000;
    }
  }
  .head_title {
    position: absolute;
    text-align: center;
    width: 100%;
    padding: 0 8%;
    color: rgb(0, 0, 0);
    top: 4%;
    h1 {
      font-size: 50px;
      &:nth-child(1) {
        font-weight: bold;
        font-size: 80px;
      }
    }
  }
  .van-field {
    height: 45px;
    width: 85%;
    padding: 0;
    background-color: transparent;
  }
  /deep/ .van-field__body {
    height: 100% !important;
  }
  .flexcolumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .myButton {
    width: 90%;
    margin: 0 auto;
    border-radius: 50px;
    border: none;
    box-shadow: 0 6px 10px 2px rgba(0, 119, 255, 0.3);
    span {
      // font-size: 16px;
      transform: scale(1.1);
      font-weight: bold;
    }
  }
  .van-field__body {
    height: 100%;
  }
  .van-button__text {
    transform: scale(2);
  }
  .footer {
    width: 75%;
    margin: 50px auto 0;
  }
  .loginfooter {
    position: fixed;
    text-align: center;
    top: calc(93%);
    width: 100%;
  }
}
</style>
